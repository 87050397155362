<template>
  <b-row>
    <b-col class="national-card mx-1 p-1 d-flex flex-column mb-1">
      <span>
        Clique no ícone para entrar em contato pelo WhatsApp:
      </span><br>
      <a
        href="https://wa.me/556199367160"
        target="_blank"
        class="d-flex justify-content-center"
      >
        <img
          :src="whatsapp"
          alt="WhatsApp"
        >
      </a>
      <a
        href="https://wa.me/556199367160"
        target="_blank"
        class="text-custom-blue text-center mt-2 h2"
      >
        (61) 9936-7160
      </a>
    </b-col>
    <b-col class="national-card mx-1 p-1 d-flex flex-column mb-1">
      <span class="mb-1">
        Aponte seu celular para o QR Code e entre em contato:
      </span>
      <div class="d-flex justify-content-center">
        <img
          :src="qrCode"
          alt="Aponte seu celular para o QR Code e entre em contato"
        >
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import qrCode from '@/assets/custom-images/pages/qrcode.png'
import whatsapp from '@/assets/custom-images/pages/whatsapp.png'

export default {
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      qrCode,
      whatsapp,
    }
  },
}
</script>

<style>
#form textarea#mensagem {
  height: 190px;
}
#form .btn.btn-custom {
  width: 138px;
}

.form-text {
  margin: 0!important;
  font-size: 14px!important;
  font-weight: 400!important;
}

div#swal2-content {
  margin: 0;
}

.national-card {
  border-radius: 6px;
  background: var(--2-opacity-color-primary-6, rgba(39, 114, 192, 0.06));
}
</style>
