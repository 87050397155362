<template>
  <div
    :style="loginStyle"
    class="auth-wrapper auth-v2"
  >
    <b-row class="auth-inner m-0 justify-content-center">
      <b-col
        lg="11"
        class="pt-5 pl-3"
      >
        <InitialHeader :only-logo="true" />
      </b-col>
      <b-col
        lg="12"
        class="d-flex align-items-center mb-5"
      >
        <b-col
          sm="12"
          md="10"
          lg="9"
          xl="6"
          class="p-2 mx-auto auth-bg rounded"
        >
          <h1 class="text-dark mb-2">
            Faça seu orçamento
          </h1>
          <budget />
        </b-col>
      </b-col>
      <!-- /Login-->
      <FooterInitial />
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, VBTooltip,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { validation } from '@core/mixins/validation/validation'
import { messages } from '@core/mixins/validation/messages'
import FooterInitial from '@/views/components/custom/footer/FooterInitial.vue'
import InitialHeader from '@/views/components/custom/page-header/InitialHeader.vue'
import Budget from '@/views/components/custom/suporte-duvidas/Budget.vue'

export default {
  title: 'Orçamento',

  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    FooterInitial,
    InitialHeader,
    Budget,
  },
  mixins: [togglePasswordVisibility, validation, messages],

  data() {
    return {
      auth: {
        email: '',
        password: '',
      },
      loginStyle: {
        'background-image': `url(${require('@/assets/custom-images/bg/bg-budgetform.png')})`,
        'background-repeat': 'no-repeat',
        'background-position': 'center center',
        'background-size': 'cover',
      },
      loadingShow: true,
    }
  },
  mounted() {
    this.$helpers.indexablePage()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
